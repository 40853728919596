import LoadExternMedia from './LoadExternMedia.js'
import CookieManager from './CookieManager.js'

declare global {
  interface Window {
    dataConsent: LoadExternMedia
  }
}

window.dataConsent = new LoadExternMedia()
const cookieManager = new CookieManager()
cookieManager.ajaxLoad()